export default {
  setTokenEndDate() {
    const date2Hours = new Date()
    date2Hours.setHours(date2Hours.getHours() + 2)
    const timeStampDate = date2Hours.getTime()
    localStorage.setItem('token_end_date', timeStampDate)
  },
  getTokenIsValid() {
    const consentEndDate = window.localStorage.getItem('token_end_date')
    const currentDate = Date.now()
    const compareDate = consentEndDate - currentDate
    return (Math.sign(compareDate) === 1)
  },
  setLocalStorageToken(token) {
    localStorage.setItem('token', token)
    this.setTokenEndDate()
  },
  getLocalStorageToken() {
    return localStorage.getItem('token')
  },
  deleteLocalStorageToken() {
    localStorage.removeItem('token')
    localStorage.removeItem('token_end_date')
  }
}
