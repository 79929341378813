import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

import TokenLocalStorage from '../services/TokenLocalStorage'
import api from '../services/API'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('../views/Dashboard'),
    meta: {
      requireLogged: true,
      layout: 'default'
    }
  },
  {
    path: '/agenda',
    name: 'Agenda',
    component: () => import('../views/Agenda'),
    meta: {
      requireLogged: true,
      layout: 'default'
    }
  },
  {
    path: '/artistes',
    name: 'Artistes',
    component: () => import('../views/Artistes'),
    meta: {
      requireLogged: true,
      layout: 'default'
    }
  },
  {
    path: '/styles',
    name: 'Styles',
    component: () => import('../views/Styles'),
    meta: {
      requireLogged: true,
      layout: 'default'
    }
  },
  {
    path: '/photos',
    name: 'Albums',
    component: () => import('../views/Albums'),
    meta: {
      requireLogged: true,
      layout: 'default'
    }
  },
  {
    path: '/residents',
    name: 'Residents',
    component: () => import('../views/Residents'),
    meta: {
      requireLogged: true,
      layout: 'default'
    }
  },
  {
    path: '/message',
    name: 'Message d\'accueil',
    component: () => import('../views/MessageAccueil'),
    meta: {
      requireLogged: true,
      layout: 'default'
    }
  },
  {
    path: '/admins',
    name: 'Administrateurs',
    component: () => import('../views/Administrateurs'),
    meta: {
      requireLogged: true,
      layout: 'default'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login'),
    meta: {
      requireLogged: false,
      layout: null
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach (async (to, from, next) => {
  if (to.meta.requireLogged) {
    if (store.state.logged && store.state.user_token) {
      next()
    } else if (TokenLocalStorage.getLocalStorageToken()) {
      if (!TokenLocalStorage.getTokenIsValid()) {
        TokenLocalStorage.deleteLocalStorageToken()
        store.commit('SET_USERNAME', null)
        store.commit('SET_USER_EMAIL', null)
        store.commit('SET_LOGGED', false)
        next({
          name: 'Login'
        })
      } else {
        const token = TokenLocalStorage.getLocalStorageToken()
        // TODO ajouter les infos user une fois que la route /me sera bonne
        api.saveAxiosToken(token)
        TokenLocalStorage.setTokenEndDate()
        store.commit('SET_USERNAME', 'CHANGE USER')
        store.commit('SET_USER_EMAIL', 'CHANGE MAIL')
        store.commit('SET_USER_TOKEN', token)
        store.commit('SET_LOGGED', true)
        next()
      }
    } else {
      next({
        name: 'Login'
      })
    }
  } else {
    next()
  }
})

export default router
