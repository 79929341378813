import axios from 'axios'

const baseApi = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL || 'https://api.lezoo.ch/api/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

const api = {
  saveAxiosToken (token) {
    baseApi.defaults.headers.common.Authorization = `Bearer ${token}`
  },

  user: {
    me () {
      return baseApi.get('user/me')
    },
    login (user) {
      return baseApi.post('user/login', user)
    },
    logout (user) {
      return baseApi.post('user/logout', user)
    },

    getUsers () {
      return baseApi.get('user')
    },
    getUserCount () {
      return baseApi.get('user/count')
    },

    addUser (user) {
      return baseApi.post('user', user)
    },
    editUser (user, userId) {
      return baseApi.put(`user/${userId}`, user)
    },
    getUser (userId) {
      return baseApi.get(`user/${userId}`)
    },
    deleteUser (userId) {
      return baseApi.post(`user/${userId}`)
    }
  },

  artiste: {
    getArtistes () {
      return baseApi.get('artists')
    },
    postArtiste (artiste) {
      return baseApi.post('artists', artiste)
    },
    getArtistesCount () {
      return baseApi.get('artists/count')
    },
    deleteArtiste (artisteId) {
      return baseApi.delete(`artists/${artisteId}`)
    },
    updateArtiste (artisteId, artiste) {
      return baseApi.put(`artists/${artisteId}`, artiste)
    },
    getArtiste (artisteId) {
      return baseApi.get(`artists/${artisteId}`)
    }
  },

  events: {
    getEvents () {
      return baseApi.get('events')
    },
    postEvent (event) {
      return baseApi.post('events', event)
    },
    getEventsCount () {
      return baseApi.get('events/count')
    },
    getNextEvent () {
      return baseApi.get('events/next')
    },
    deleteEvent (eventId) {
      return baseApi.delete(`events/${eventId}`)
    },
    updateEvent (eventId, event) {
      return baseApi.put(`events/${eventId}`, event)
    },
    getEvent (eventId) {
      return baseApi.get(`events/${eventId}`)
    }
  },

  photos: {
    postPhoto (photo) {
      return baseApi.post('photos', photo)
    },
    getPhotos () {
      return baseApi.get('photos')
    },
    getPhotosCount () {
      return baseApi.get('photos/count')
    },
    deletePhoto (photoId) {
      return baseApi.delete(`photos/${photoId}`)
    },
    getPhoto (photoId) {
      return baseApi.get(`photos/${photoId}`)
    },
    updatePhoto (photoId, photo) {
      return baseApi.put(`photos/${photoId}`, photo)
    }
  },

  media: {
    postMedia (media) {
      const bodyFormData = new FormData()
      bodyFormData.append('file', media)
      return baseApi.post('media/upload', bodyFormData)
    },
    getMedia (media) {
      return baseApi.get(`media/${media}`)
    }
  },

  styles: {
    getStyles () {
      return baseApi.get('styles')
    },
    postStyle (styles) {
      return baseApi.post('styles', styles)
    },
    getStylesCount () {
      return baseApi.get('styles/count')
    },
    deleteStyle (stylesId) {
      return baseApi.delete(`styles/${stylesId}`)
    },
    updateStyle (stylesId, styles) {
      return baseApi.put(`styles/${stylesId}`, styles)
    },
    getStyle (stylesId) {
      return baseApi.get(`styles/${stylesId}`)
    }
  }

}

export default api
