<template>
  <v-app>
    <component v-if="currentLayout" :is="currentLayout" />
    <v-main>
      <v-container fluid>
        <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
    defaultLayout: () => import('./layout/default')
  },
  computed: {
    currentLayout() {
      if (this.$route.meta.layout) {
        return this.$route.meta.layout + 'Layout'
      }
      return null
    }
  }
};
</script>
