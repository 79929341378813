import Vue from 'vue'
import Vuex from 'vuex'

import TokenLocalStorage from '../services/TokenLocalStorage'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    username: null,
    user_email: null,
    user_token: null,
    logged: false,
    counts: {
      artistes: 0,
      users: 0,
      events: 0,
      styles: 0,
      albums: 0,
    },
    artistes: [],
    users: [],
    events: [],
    styles: [],
    albums: [],
  },
  mutations: {
    SET_USERNAME (state, username) {
      state.username = username
    },
    SET_USER_EMAIL (state, email) {
      state.user_email = email
    },
    SET_USER_TOKEN (state, token) {
      state.user_token = token
    },
    SET_LOGGED (state, logged) {
      state.logged = logged
    },
    SET_ARTISTES_COUNT (state, count) {
      state.counts.artistes = count
    },
    SET_EVENTS_COUNT (state, count) {
      state.counts.events = count
    },
    SET_ARTISTES (state, artistes) {
      state.artistes = artistes
    },
    ADD_ARTISTE (state, artiste) {
      state.artistes.push(artiste)
    },
    DELETE_ARTISTE (state, artisteId) {
      const artisteIndex = state.artistes.findIndex((art) => art.id === artisteId)
      state.artistes.splice(artisteIndex, 1)
    },
    EDIT_ARTISTE (state, artiste) {
      const artisteIndex = state.artistes.findIndex((art) => art.id === artiste.id)
      state.artistes[artisteIndex] = artiste
    },
    SET_STYLES (state, styles) {
      state.styles = styles
    },
    ADD_STYLE (state, style) {
      state.styles.push(style)
    },
    DELETE_STYLE (state, styleId) {
      const styleIndex = state.styles.findIndex((style) => style.id === styleId)
      state.styles.splice(styleIndex, 1)
    },
    EDIT_STYLE (state, style) {
      const styleIndex = state.styles.findIndex((sty) => sty.id === style.id)
      Vue.set(state.styles, styleIndex, style)
    },
    SET_USERS (state, users) {
      state.users = users
    },
    ADD_USER (state, user) {
      state.users.push(user)
    },
    DELETE_USER (state, userId) {
      const userIndex = state.users.findIndex((use) => use.id === userId)
      state.users.splice(userIndex, 1)
    },
    EDIT_USER (state, user) {
      const userIndex = state.users.findIndex((use) => use.id === user.id)
      Vue.set(state.users, userIndex, user)
    },
  },
  actions: {
    saveLogin({ commit }, user) {
      if (user) {
        TokenLocalStorage.setLocalStorageToken(user.token)
        commit('SET_USERNAME', user.name)
        commit('SET_USER_EMAIL', user.email)
        commit('SET_USER_TOKEN', user.token)
        commit('SET_LOGGED', true)
      }
    },
    logout({ commit }) {
      TokenLocalStorage.deleteLocalStorageToken()
      commit('SET_USERNAME', null)
      commit('SET_USER_EMAIL', null)
      commit('SET_LOGGED', false)
    },
    updateDashboardCount({ commit }, counts) {
      commit('SET_ARTISTES_COUNT', counts.artistes)
      commit('SET_EVENTS_COUNT', counts.events)
    }
  },
  modules: {
  }
})
